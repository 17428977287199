import { Rule } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import Image from "next/image";
import { ReactNode, useEffect, useState } from "react";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Box } from "simple-effing-primitive-layout";

import MobileViewSwitcher from "../mobile-view-switcher/mobile-view-switcher";
import ReusablePreSlantedContainer from "../reusable-pre-slanted-container/reusable-pre-slanted-container";

export const ReusableGradientHero = ({
  children,
  image,
  slides = [],
  slide = 0,
  previous,
  next,
  src,
  right,
  backdrop,
  justify,
  span,
  slanted = true,
  paddingTop = 170,
}: {
  children?: ReactNode;
  right?: ReactNode;
  slides?: ReactNode[];
  next?: (value: number) => void;
  previous?: (value: number) => void;
  slide?: number;
  image?: "blue" | "green" | "yellow" | "purple" | "none";
  justify?: "center";
  src?: string;
  backdrop?: string;
  gradient?:
    | [string[]]
    | [string[], string[]]
    | [string[], string[], string[]]
    | [string[], string[], string[], string[]]
    | [string[], string[], string[], string[], string[]];
  span?: number;
  slanted?: boolean;
  paddingTop?: number;
}) => {
  const BlueImageSource =
    "/assets/libs/simple-ui/reusable-gradient-hero/assets/blue.webp";
  const GreenImageSource =
    "/assets/libs/simple-ui/reusable-gradient-hero/assets/green.webp";
  const YellowImageSource =
    "/assets/libs/simple-ui/reusable-gradient-hero/assets/yellow.webp";
  const PurpleImageSource =
    "/assets/libs/simple-ui/reusable-gradient-hero/assets/purple.webp";

  const [s, _s] = useState(slide || 0);
  const [interval, _interval] = useState<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (slide !== s) {
      _s(slide);
    }
  }, [slide]);

  const factory = (child: React.ReactElement): React.ReactElement => {
    const node = React.cloneElement(child, {
      identifier: slide,
    });
    return node;
  };

  const getImage = () => {
    switch (image) {
      case "blue":
        return BlueImageSource;
      case "green":
        return GreenImageSource;
      case "yellow":
        return YellowImageSource;
      case "purple":
        return PurpleImageSource;

      default:
        return "none";
    }
  };

  return (
    <MobileViewSwitcher
      render={(mobile) => {
        return (
          <Box
            parse={`pa:16 p:relative pt:${paddingTop} oa:hidden`}
            style={{
              height: mobile ? 300 : 500,
            }}
          >
            <Box parse="p:absolute i:1 w:100% h:100% t:0 l:0 r:unset b:unset">
              <Image
                src={src || getImage()}
                layout="fill"
                priority
                objectPosition="top center"
                objectFit="cover"
              />
            </Box>

            {slides?.length ? (
              <TransitionGroup childFactory={factory}>
                {[
                  <CSSTransition
                    key={slide}
                    timeout={2000}
                    classNames={{
                      enter: "lite-enter",
                      enterActive: "lite-enter-active",
                      exit: "lite-exit",
                      exitActive: "lite-exit-active",
                    }}
                  >
                    <Box parse="p:absolute z:1 i:1 t:0 l:0 w:100% h:100%">
                      <Image
                        src={src || getImage()}
                        sizes="100vw"
                        layout="fill"
                        priority
                        objectFit="contain"
                      />
                    </Box>
                  </CSSTransition>,
                ]}
              </TransitionGroup>
            ) : undefined}

            <Box parse="p:relative z:2 i:2">
              <ReusablePreSlantedContainer active={false} amount={72}>
                <div className="container" style={{ position: "relative" }}>
                  {slides.length ? (
                    <Box
                      parse="b:unset r:0 l:unset p:absolute"
                      style={{
                        top: "calc(100% + 24px)",
                      }}
                    >
                      <Box css="d:inline-flex a:center">
                        <Box css="d:inline-flex a:baseline">
                          <Rule parse="!lt" size={24}>
                            {(slide + 1).toString().padStart(2, "0")}
                          </Rule>
                          <Rule parse="!lt d:block mr:1 o:0.5" size={16}>
                            {` /${slides.length.toString().padStart(2, "0")}`}
                          </Rule>
                        </Box>

                        {previous && (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 32 32"
                            fill="none"
                            style={{
                              marginRight: 12,
                              marginLeft: 16,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (interval) {
                                clearInterval(interval);
                                _interval(null);
                              }

                              if (slide > 0 && previous) {
                                previous(slide - 1);
                                _s(slide - 1);
                              } else {
                                previous?.(slides.length - 1);
                                _s(slides.length - 1);
                              }
                            }}
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="31"
                              height="31"
                              rx="15.5"
                              fill="white"
                              fillOpacity="0.2"
                            />
                            <path
                              d="M18.2674 10.6018C18.2674 10.7318 18.2169 10.8615 18.1166 10.9598L12.9424 16.0083L18.1059 21.0465C18.3039 21.2393 18.3074 21.556 18.1146 21.7535C17.9219 21.9513 17.6051 21.9553 17.4074 21.7623L11.8771 16.3663C11.6844 16.1778 11.6844 15.839 11.8771 15.6505L17.4181 10.2438C17.6159 10.051 17.9324 10.0548 18.1254 10.2525C18.2201 10.3498 18.2674 10.4758 18.2674 10.6018Z"
                              fill="white"
                            />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="31"
                              height="31"
                              rx="15.5"
                              stroke="#E1E1E1"
                            />
                          </svg>
                        )}

                        {next && (
                          <svg
                            width="40"
                            height="40"
                            viewBox="0 0 32 32"
                            fill="none"
                            onClick={() => {
                              if (interval) {
                                clearInterval(interval);
                                _interval(null);
                              }

                              if (slide + 1 < slides.length && next) {
                                next?.(slide + 1);
                                _s(slide + 1);
                              } else {
                                next?.(0);
                                _s(0);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <rect
                              x="-0.5"
                              y="0.5"
                              width="31"
                              height="31"
                              rx="15.5"
                              transform="matrix(-1 0 0 1 31 0)"
                              fill="white"
                              fillOpacity="0.2"
                            />
                            <path
                              d="M13.7326 10.6018C13.7326 10.7318 13.7831 10.8615 13.8834 10.9598L19.0576 16.0083L13.8941 21.0465C13.6961 21.2393 13.6926 21.556 13.8854 21.7535C14.0781 21.9513 14.3949 21.9553 14.5926 21.7623L20.1229 16.3663C20.3156 16.1778 20.3156 15.839 20.1229 15.6505L14.5819 10.2438C14.3841 10.051 14.0676 10.0548 13.8746 10.2525C13.7799 10.3498 13.7326 10.4758 13.7326 10.6018Z"
                              fill="white"
                            />
                            <rect
                              x="-0.5"
                              y="0.5"
                              width="31"
                              height="31"
                              rx="15.5"
                              transform="matrix(-1 0 0 1 31 0)"
                              stroke="#E1E1E1"
                            />
                          </svg>
                        )}
                      </Box>
                    </Box>
                  ) : undefined}
                  <Row justify={justify}>
                    <Col
                      xs={24}
                      sm={24}
                      md={span || 18}
                      lg={span || 14}
                      xl={span || 12}
                    >
                      {slides.length ? (
                        <Box css="w:100% h:100% p:relative" parse="mh:200">
                          <TransitionGroup childFactory={factory}>
                            {[
                              <CSSTransition
                                key={slide}
                                timeout={2000}
                                classNames={{
                                  enter: "lite-enter",
                                  enterActive: "lite-enter-active",
                                  exit: "lite-exit",
                                  exitActive: "lite-exit-active",
                                }}
                              >
                                <Box css="oa:visible p:absolute t:0 l:0 w:100% h:100%">
                                  {slides[slide]}
                                </Box>
                              </CSSTransition>,
                            ]}
                          </TransitionGroup>
                        </Box>
                      ) : (
                        children
                      )}
                    </Col>
                    {right ? (
                      <Col
                        xs={24}
                        sm={24}
                        md={span ? 24 - span : 6}
                        lg={span ? 24 - span : 10}
                        xl={12}
                      >
                        {right}
                      </Col>
                    ) : undefined}
                  </Row>
                </div>
              </ReusablePreSlantedContainer>
            </Box>

            {backdrop ? (
              <Box parse="p:absolute i:1 w:100% h:100% t:0 l:0 r:unset b:unset">
                <Image
                  src={backdrop}
                  layout="fill"
                  priority
                  objectPosition="center"
                  objectFit="cover"
                />
              </Box>
            ) : undefined}
          </Box>
        );
      }}
    />
  );
};

export default ReusableGradientHero;
